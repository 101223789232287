<mat-sidenav-container class="mlayout"
   [style.--page-title]="customerConfig?.style?.headings"
   [style.--mat-card-title]="customerConfig?.style?.headings"
   [style.--bs-link-hover-color]="customerConfig?.style?.links"
   [style.--bs-link-color]="customerConfig?.style?.links"
   [style.--button-primary-background]="customerConfig?.style?.primaryButton.background_color"
   [style.--button-primary-color]="customerConfig?.style?.primaryButton.color"
   [style.--button-secondary-background]="customerConfig?.style?.secondaryButton.background_color"
   [style.--button-secondary-color]="customerConfig?.style?.secondaryButton.color"
   [style.--header-background]="customerConfig?.style?.header.background_color"
   [style.--header-color]="customerConfig?.style?.header.color"
   [style.--breadcrumb-background]="customerConfig?.style?.breadcrumb.background_color"
   [style.--breadcrumb-color]="customerConfig?.style?.breadcrumb.color"
   [style.--breadcrumb-secondary-background]="customerConfig?.style?.breadcrumbSecondaryButton.background_color"
   [style.--breadcrumb-secondary-color]="customerConfig?.style?.breadcrumbSecondaryButton.color"
   [style.--mat-tab-color]="customerConfig?.style?.submenuLine"
   [style.--mat-tab-border-color]="customerConfig?.style?.submenuLine"
   [style.--multi-select-background]="customerConfig?.style?.listingMultiSelect.background_color"
   [style.--multi-select-color]="customerConfig?.style?.listingMultiSelect.color"
   [style.--mat-input-checkbox-ripple]="customerConfig?.style?.checkboxes"
   [style.--mat-input-text]="customerConfig?.style?.hover"
   [style.--mat-table-heading]="customerConfig?.style?.headings"
   [hasBackdrop]="false">
    <mat-sidenav #sidebar [mode]="(isMobile) ? 'push': 'side'" [opened]="menuOpen">
        <app-msidebar
                (menuClick)="menuClose()"
        ></app-msidebar>
    </mat-sidenav>
    <mat-sidenav-content>
        <app-mheader (menuClick)="menuClick()"></app-mheader>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
