export const environment = {
  production: true,
  url: 'bo.funeup.fr',
  apiUrl: 'https://api-preprod.funeup.fr',
  apiUrlSo: 'https://pro.sereniteobseques.fr/uploads',
  clientId: '1_5n8tfr5ho7swosc4og0c8kgw4c8kckwok4ws8kss44ko8g80wo',
  clientSecret: '1ox9oa9l1mo00gk4s0wckwc4cgw80cs4cgkswg4gcg8skw8084',
  cookieDomain: window.location.host,
  refreshTokenLifeTime: 1209600,
  cookieAccessTokenName: 'ac',
  cookieRefreshTokenName: 'rf',
  cookieExpiresName: 'expires',
  cookieType: 'type',
  GTM_ID: 'GTM-MMR6LBZ',
};
