import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'apiUrl'
})
export class ApiUrlPipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      return (value.includes('https://')) ? value : environment.apiUrl + value;
    }

    return null;
  }
}
