import { Pipe, PipeTransform } from '@angular/core';
import { CondolenceState } from '@models/condolence-state';

@Pipe({
  name: 'codolenceState'
})
export class CodolenceStatePipe implements PipeTransform {

  transform(value: string): any {
    const state = CondolenceState.find((params) => params.value === value);
    return '<span><i class="icon ' + state.icon + '"></i><span>' + state.label + '</span></span>';
  }

}
