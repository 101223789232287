import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
/** SERVICES */
import { BaseService } from './base.service';
/** RXJS */
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminService extends BaseService {
  private prefix = '/admin';

  constructor(
    httpClient: HttpClient
  ) {
    super(httpClient);
  }

  me(): Observable<any> {
    return this.get(this.prefix + '/user/me');
  }
}
