import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stylePropertiesTranslator'
})
export class StylePropertiesTranslatorPipe implements PipeTransform {

  private dictionary = {
    // Titles
    banner: 'Bannière',
    content: 'Contenu',
    discountBar: 'Barre de prix réduit',
    backButton: 'Bouton retour',
    customButton: 'Bouton',
    customStep: 'Étapes',
    filter: 'Filtre',
    footer: 'Bas de page',
    horizontalBar: 'Barre horizontale',
    label: 'Étiquette',
    link: 'Lien',
    modeSelect: 'Mode sélection',
    orderButton: 'Bouton d\'achat',
    payButton: 'Bouton de paiement',
    phone: 'Téléphone',
    pickButton: 'Bouton choisir',
    picto: 'Picto',
    reinsurance: 'Réassurance',
    stepper: 'Étapes par étapes',
    stepperDefault: 'Étapes par étapes - couleur par défaut',
    answerButton: 'Bouton répondre',
    links: 'Liens',
    menuBox: 'Menu sélectionné',
    menuDefault: 'Couleur du texte dans le menu',
    menuHover: 'Menu au survol',
    printButton: 'Bouton imprimer',

    // CSS Properties
    background_color: 'Couleur de fond',
    border_color: 'Couleur de la bordure',
    color: 'Couleur',

    background: 'Couleur de fond',
    familySpaceButton: 'Bouton espace famille',
    menu: 'Menu',
    funeralBlockStepper: 'Stepper funérailles',
    phoneHelp: 'Numéro de téléphone d\'aide',
    breadcrumb: 'Breadcrumb',
    header: 'Haut de page',
    pagination: 'Pagination',
    shareButton: 'Bouton de partage',
    commonButton: 'Bouton commun',
    funeralBlockFooter: 'Footer funérailles',
    homeButton: 'Bouton page d\'accueil',
    showButton: 'Bouton consulter',
    color_disabled: 'Couleur désactivé',
    picto_color: 'Couleur de picto',
    condolencesCancelButton: 'Bouton Annuler du formulaire',
    condolencesSubmitButton: 'Bouton Envoyer du formulaire',
    contactPrimaryButtons: 'Contact - bouton primaire',
    contactSecondaryButtons: 'Contact - bouton secondaire',
    datepicker: 'Sélection de date - couleur',
    headerBackground: 'Header - couleur de fond',
    headings: 'Couleur des titres des rubriques',
    homepageApproveButton: 'Page d\'accueil - approuver',
    homepageDisapproveButton: 'Page d\'accueil - désapprouver',
    homepageDeletedButton: 'Page d\'accueil - supprimer',
    homepagePicto: 'Page d\'accueil - pictos',
    hover: 'Survol champs à renseigner',
    breadcrumbActionsButton: 'Breadcrumb - bouton actions',
    breadcrumbPrimaryButton: 'Breadcrumb - bouton principale',
    breadcrumbSecondaryButton: 'Breadcrumb - bouton secondaire',
    checkboxes: 'Checkbox - couleur de fond',
    checkboxesText: 'Checkbox - couleur de texte',
    listingDefault: 'Listing - couleur',
    listingMultiSelect: 'Listing - multi sélection',
    listingNoResults: 'Listing - pas de résultats',
    listingResetButton: 'Listing - bouton réinitialisation',
    modalCancelButton: 'Popup - bouton annuler',
    modalSaveButton: 'Popup - bouton sauvegarder',
    opinionAnswerButton: 'Avis clients - bouton répondre',
    opinionSaveAnswerButton: 'Avis clients Modale - bouton Enregistrer votre réponse',
    opinionUpdateButton: 'Avis clients - bouton Modifier',
    primaryButton: 'Bouton principal',
    secondaryButton: 'Bouton secondaire',
    primary: 'Principal',
    secondary: 'Secondaire',
    radios: 'Bouton radio',
    signinBubble: 'Signin - Bulle',
    signinButton: 'Signin - Bouton',
    signinPicto: 'Signin - Pictogramme',
    signinTitle: 'Signin - Titre',
    submenuLine: 'Barre sous menu',
    toggle: 'Toggle - couleur de fond',
    toggleText: 'Toggle - couleur de texte',
    selectedMenu: 'Menu selectionné',
    nonExistingPlaceBloc: 'Lieu - bloc non existant',
    loader: 'Loader',
    listingLine: 'Listing - couleur de ligne',
    addPlaceButton: 'Lieu - bouton ajouter un lieu',
    places: 'Lieu - selectionné',
    statsPrimaryColor: 'Stats - Couleur du premier chiffre',
    statsSecondaryColor: 'Stats - Couleur du second chiffre',
    statsBaseColors1: 'Stats - Couleur de base 1',
    statsBaseColors2: 'Stats - Couleur de base 2',
    statsBaseColors3: 'Stats - Couleur de base 3',
    statsBaseColors4: 'Stats - Couleur de base 4',
    badge: 'Badge quantité',
    confirmation: 'Sidebar + page confirmation',

  };

  transform(value: string): unknown {
    if (this.dictionary[value] != null) {
      return this.dictionary[value];
    }
    console.error(`StylePropertiesTranslatorPipe: Can't find property ${value}`);
  }

}
