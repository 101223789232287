import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
/** SERVICES */
import { BaseService } from './base.service';
/** MODELS */
import { ProfilePreferences } from '@models/profile-preferences';
import { ProfileConfig } from '@models/profile-config.data-list';
/** RXJS */
import { Observable } from 'rxjs';
import { Services } from '@models/services';
import { StyleCustomization } from '@models/style-customization';
import { ReviewConfig } from '@models/review-config';
import { DeathNoticeCustomization } from '@models/death-notice-customization';
import { ProfileIntegrations } from '@models/profile-integrations';
import { Document, DocumentList } from '@models/document.data-list';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseService {
  public static PROFILE_URL = '/admin/profile/';
  public static PROFILE_CONFIG_URL = '/admin/profile-config/';

  public constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getPreferences(profileId: string): Observable<ProfilePreferences> {
    return this.get(ProfileService.PROFILE_URL + profileId + '/preferences');
  }

  public updatePreferences(profileId: string, data: ProfilePreferences): Observable<void> {
    return this.post(ProfileService.PROFILE_URL + profileId + '/preferences', data);
  }

  public getProfileConfig(profileId: string): Observable<ProfileConfig> {
    return this.get(ProfileService.PROFILE_CONFIG_URL + profileId);
  }

  public saveProfileConfig(profileId: string, profileConfig: ProfileConfig): Observable<void> {
    return this.post(ProfileService.PROFILE_CONFIG_URL + profileId, profileConfig);
  }

  public postProfileServices(profileId: string, externalService: any): Observable<void> {
    return this.post(ProfileService.PROFILE_URL + profileId + '/services', externalService);
  }

  public getProfileServices(profileId: string): Observable<Services> {
    return this.get(ProfileService.PROFILE_URL + profileId + '/services');
  }

  public postProfileIntegrations(profileId: string, integrations: ProfileIntegrations): Observable<void> {
    return this.post(ProfileService.PROFILE_URL + profileId + '/integrations', integrations);
  }

  postProfileShop(profileId: string, styles: StyleCustomization): Observable<StyleCustomization> {
    return this.post(ProfileService.PROFILE_URL + profileId + '/shop', styles);
  }
  public getProfileShop(profileId: string): Observable<StyleCustomization> {
    return this.get(ProfileService.PROFILE_URL + profileId + '/shop');
  }

  postProfileUdiana(profileId: string, styles: StyleCustomization): Observable<StyleCustomization> {
    return this.post(ProfileService.PROFILE_URL + profileId + '/shop', styles);
  }
  public getProfileUdiana(profileId: string): Observable<StyleCustomization> {
    return this.get(ProfileService.PROFILE_URL + profileId + '/udiana');
  }

  postProfileShare(profileId: string, body: any): Observable<any> {
    return this.post(ProfileService.PROFILE_URL + profileId + '/death-notice', body);
  }

  postProfileReview(profileId: string, reviewConfig: ReviewConfig): Observable<ReviewConfig> {
    return this.post(ProfileService.PROFILE_URL + profileId + '/review', reviewConfig);
  }

  postProfileDeathNoticeStyles(profileId: string, body?: any): Observable<DeathNoticeCustomization> {
    return this.post(ProfileService.PROFILE_URL + profileId + '/death-notice-web', body);
  }
  public getProfileDeathNoticeStyles(profileId: string): Observable<DeathNoticeCustomization> {
    return this.get(ProfileService.PROFILE_URL + profileId + '/death-notice-web');
  }

  getProfileDocuments(profileId: string, options: any): Observable<DocumentList> {
    return this.get(ProfileService.PROFILE_URL + profileId + '/documents', options);
  }

  deleteProfileDocument(profileId: string, documentId): Observable<any> {
    return this.delete(ProfileService.PROFILE_URL + profileId + '/documents/' + documentId);
  }

  putProfileDocument(profileId: string, document: Document): Observable<any> {
    const {id, ...body} = document;
    return this.post(ProfileService.PROFILE_URL + profileId + '/documents/' + id, this.formatFormData(body));
  }

  postProfileDocument(profileId: string, document: Document): Observable<any> {
    return this.post(ProfileService.PROFILE_URL + profileId + '/documents' ,  this.formatFormData(document));
  }
}
