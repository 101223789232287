import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
/** RXJS */
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
/** SERVICES */
import { BaseService } from '@services/base.service';
/** MODELS */
import { ProfileConfig } from '@models/profile-config.data-list';

@Injectable()
export class ProfileConfigResolver  {
  private endpoint = '/admin/profile-config/';

  constructor(
    private readonly service: BaseService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProfileConfig | null> {
    return this.service.get(this.endpoint + route.params.profileId).pipe(
      catchError(() => {
          this.router.navigate(['/unauthorized']);
          return of(null);
        }
      ));
  }
}
