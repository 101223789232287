import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { OauthService } from '@services/oauth.service';
import { User } from '@models/user.data-list';
import { tap } from 'rxjs/operators';
import { UserRoles } from '@models/user-roles';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit, OnDestroy {

  @Input() appHasRole: UserRoles[];
  authenticateUserSubscription: Subscription;

  isVisible = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private oauthService: OauthService
  ) {}

  ngOnInit(): void {
    //  We subscribe to the roles$ to know the roles the user has
    this.authenticateUserSubscription = this.oauthService.getAuthenticatedUser()
      .pipe(
        tap((user: User) => {
          // If he doesn't have any roles, we clear the viewContainerRef
          if (user === null || user === undefined || (!!user && !user.role)) {
            this.viewContainerRef.clear();
          } else {
            // If the user has the role needed to
            // render this component we can add it
            if (!this.appHasRole || (this.appHasRole && this.appHasRole.includes(user.role))) {
              // If it is already visible (which can happen if
              // his roles changed) we do not need to add it a second time
              if (!this.isVisible) {
                // We update the `isVisible` property and add the
                // templateRef to the view using the
                // 'createEmbeddedView' method of the viewContainerRef
                this.isVisible = true;
                this.viewContainerRef.createEmbeddedView(this.templateRef);
              }
            } else {
              // If the user does not have the role,
              // we update the `isVisible` property and clear
              // the contents of the viewContainerRef
              this.isVisible = false;
              this.viewContainerRef.clear();
            }
          }

        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.authenticateUserSubscription.unsubscribe();
  }
}
