import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, tap } from 'rxjs/operators';
import { UpdateReloadDialogComponent } from '@components/update-reload-dialog/update-reload-dialog.component';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    public dialog: MatDialog,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute
  ) {
  }

  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      this.ngZone.run(() => {
        const dialogRefDelete = this.dialog.open(UpdateReloadDialogComponent, {
          width: '600px',
          data: {
            customerConfig: this.activatedRoute.snapshot.children[0]?.data?.customerConfig
          }
        });

        dialogRefDelete.afterClosed()
          .pipe(
            filter((r) => !!r),
            tap((r) => {
              window.location.reload();
            })
          ).subscribe();
      });
    } else {
      throw new Error(error);
    }
  }
}
