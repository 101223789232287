import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
/** RXJS */
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, tap } from 'rxjs/operators';
/** SERVICES */
import { BaseService } from './base.service';
import { AdminService } from './admin.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie';
import { TokenCookieStorageService } from './token-cookie-storage.service';
/** MODELS */
import { Token } from '@models/token';
/** UTILS */
import { HttpUrlEncoder } from '@utils/url-encoder';
import * as moment_ from 'moment';
import { User } from '@models/user.data-list';

const moment = moment_;

@Injectable({
  providedIn: 'root',
})
export class OauthService extends BaseService {
  static endpoint = '/oauth/v2/token';
  static endpointRequestPassword = '/admin/resetting/request';
  private prefixAdmin = '/admin';
  private authenticatedUserSubject: BehaviorSubject<User> =
    new BehaviorSubject<User>(null);
  private hasCurrentUser = false;
  private isFetching = false;

  constructor(
    httpClient: HttpClient,
    private adminService: AdminService,
    private cookieService: CookieService,
    private router: Router,
    private tokenStorage: TokenCookieStorageService
  ) {
    super(httpClient);
  }

  public getCurrentUser(): User {
    return this.authenticatedUserSubject.getValue();
  }
  public getAuthenticatedUser(): Observable<User> {
    if (!this.hasCurrentUser && !this.isFetching) {
      return this.fetchCurrentUser();
    } else {
      return this.authenticatedUserSubject
        .asObservable()
        .pipe(
          distinctUntilChanged(
            (prev, curr) => !!prev && !!curr && prev.id === curr.id
          )
        );
    }
  }

  public fetchCurrentUser(): Observable<User> {
    this.isFetching = true;

    return this.adminService.me().pipe(
      tap((user: User) => {
        if (user) {
          this.hasCurrentUser = true;
          this.authenticatedUserSubject.next(user);
          this.isFetching = false;
        } else {
          this.authenticatedUserSubject.next(null);
          this.hasCurrentUser = false;
          this.isFetching = false;
        }
      }),
      catchError(() => {
        this.authenticatedUserSubject.next(null);
        this.logout();
        this.hasCurrentUser = false;
        this.isFetching = false;

        return of(null);
      })
    );
  }

  public getExpiredAt(): string | null {
    return this.cookieService.get(environment.cookieExpiresName);
  }

  public getAccessToken(): string | null {
    return this.cookieService.get(environment.cookieAccessTokenName);
  }

  public getRefreshToken(): string | null {
    return this.cookieService.get(environment.cookieRefreshTokenName);
  }

  public tokenIsExpired(): boolean {
    return parseInt(this.getExpiredAt(), 10) <= Date.now();
  }

  public isAuthenticated(): boolean {
    return (
      (this.getAccessToken() !== undefined && !this.tokenIsExpired()) ||
      this.getRefreshToken() !== undefined
    );
  }

  requestPassword(email: string): Observable<any> {
    return this.post<any>(
      OauthService.endpointRequestPassword,
      new HttpParams({
        fromObject: { username: email },
        encoder: new HttpUrlEncoder(),
      }),
      {
        headers: new HttpHeaders({
          'Content-type': 'application/x-www-form-urlencoded;',
        }),
      }
    );
  }

  authenticateUser(username: string, password: string): Observable<Token> {
    return this.authenticate({
      grant_type: 'password',
      username,
      password,
      client_id: environment.clientId,
      client_secret: environment.clientSecret,
    });
  }

  authenticateWithRefreshToken(refreshToken: string): Observable<Token> {
    return this.authenticate({
      grant_type: 'refresh_token',
      client_id: environment.clientId,
      client_secret: environment.clientSecret,
      refresh_token: refreshToken,
    });
  }

  authenticate(payload: { [name: string]: string }): Observable<any> {
    return this.post<any>(
      OauthService.endpoint,
      new HttpParams({
        fromObject: payload,
        encoder: new HttpUrlEncoder(),
      }),
      {
        headers: new HttpHeaders({
          'Content-type': 'application/x-www-form-urlencoded;',
        }),
      }
    ).pipe(
      map(
        (token: any) =>
          ({
            type: token.token_type,
            accessToken: token.access_token,
            refreshToken: token.refresh_token,
            expiresAt: moment().utc().add(token.expires_in, 's').toDate(),
            scope: token.scope,
          } as Token)
      ),
      tap((token: Token) => {
        this.storeCredentials(token);
      })
    );
  }

  retrieveCredentials(): Partial<Token> {
    return {
      accessToken: this.cookieService.get(environment.cookieAccessTokenName),
      refreshToken: this.cookieService.get(environment.cookieRefreshTokenName),
    };
  }

  storeCredentials(token: Token): void {
    try {
      this.tokenStorage.token = {
        type: token.type,
        accessToken: token.accessToken || undefined,
        refreshToken: token.refreshToken || undefined,
        expiresAt: token.expiresAt || undefined,
        scope: token.scope || undefined,
      };
    } catch (e) {
      console.error(e);
    }
  }

  destroyCredentials(): void {
    if (this.isAuthenticated) {
      this.authenticatedUserSubject.next(undefined);
    }

    this.cookieService.remove(environment.cookieAccessTokenName);
    this.cookieService.remove(environment.cookieRefreshTokenName);
    this.cookieService.remove(environment.cookieExpiresName);
    this.cookieService.remove(environment.cookieType);
  }

  logout(): void {
    this.destroyCredentials();
    this.router.navigateByUrl('/signin');
  }
}
