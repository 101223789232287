<mat-toolbar class="mat-elevation-z6" [style.--button-secondary-background]="customerConfig?.style?.header.background_color"
             [style.--button-secondary-color]="customerConfig?.style?.header.color"
>
    <button mat-icon-button (click)="menuClick.emit()" class="mheader__menu" aria-label="Menu">
        <mat-icon>menu</mat-icon>
    </button>
    <div class="flex-spacer"></div>
    <app-profile-selector></app-profile-selector>
    <div class="flex-spacer"></div>
    <a mat-icon-button matTooltip="Besoin d'aide" href="https://24905542.hs-sites-eu1.com/knowledge"  target="_blank">
        <mat-icon fontIcon="help" class="me-2"></mat-icon>
    </a>

    <button
        *ngIf="currentUser"
        mat-flat-button color="primary"
        [matMenuTriggerFor]="menuSettings"
    >
        <ng-container *ngIf="!isMobile">
            {{ currentUser.firstname }} {{ currentUser.lastname }}
            <span class="icon caret profile-selector__icon d-inline-flex mx-1"></span>
        </ng-container>
        <ng-container *ngIf="isMobile">
            <mat-icon class="d-flex align-items-center">person</mat-icon>
        </ng-container>
    </button>
    <mat-menu #menuSettings="matMenu" xPosition="before">
        <a *appHasRole="[roles.SUPER_ADMIN, roles.ADMIN, roles.STANDARD]" mat-menu-item [routerLink]="['/information']">Mes informations</a>
        <a *appHasRole="[roles.ADMIN]" mat-menu-item [routerLink]="['/invoice/list']">Factures</a>
        <mat-divider *appHasRole="[roles.SUPER_ADMIN, roles.ADMIN, roles.STANDARD]"></mat-divider>
        <button mat-menu-item (click)="logout()">Déconnexion</button>
    </mat-menu>
</mat-toolbar>
