<mat-form-field>
    <mat-label>
        {{ filter.label }}
    </mat-label>
    <input type="text" [placeholder]="filter.placeholder" aria-label="" matInput [formControl]="control" [matAutocomplete]="auto">
    <button *ngIf="control.value && !isLoading" matSuffix mat-icon-button aria-label="Clear" (click)="clearControl()">
        <mat-icon>close</mat-icon>
    </button>
    <mat-icon *ngIf="isLoading"  matSuffix>
        <mat-spinner diameter="20"></mat-spinner>
    </mat-icon>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="formatLabel.bind(this)" [autoSelectActiveOption]="true" (optionSelected)="updateControl($event)">
        <mat-option *ngFor="let option of (filteredData | async)?.results" [value]="option.id">
            {{option.name}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>