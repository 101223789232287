import { Injectable } from '@angular/core';
/** NGX */
import { CookieService } from 'ngx-cookie';
/** MODELS */
import { Token } from '@models/token';
/** ENVIRONMENTS */
import { environment } from 'src/environments/environment';
/** UTILS */
import * as moment_ from 'moment';
/** RXJS */
import { BehaviorSubject, Observable } from 'rxjs';

const moment = moment_;

@Injectable({
  providedIn: 'root'
})
export class TokenCookieStorageService {
  constructor(
    private readonly cookieService: CookieService,
  ) {
  }

  protected tokenSubject: BehaviorSubject<Token> = new BehaviorSubject<Token>(undefined);

  /**
   * Gets the token
   */
  get token(): Token {
    this.tokenSubject.next(this.retrieve());

    return this.tokenSubject.getValue();
  }

  /**
   * Sets the token
   */
  set token(token: Token) {
    this.store(token);
    this.tokenSubject.next(token);
  }

  /**
   * Gets an observable that emits every time a new token is stored
   */
  get tokenChanges(): Observable<Token> {
    return this.tokenSubject.asObservable();
  }

  retrieve(): Token {
    const accessToken: string = this.cookieService.get(environment.cookieAccessTokenName);
    const refreshToken: string = this.cookieService.get(environment.cookieRefreshTokenName);
    const expiresAt: string = this.cookieService.get(environment.cookieExpiresName);

    if (!accessToken && !refreshToken) {
      return undefined;
    }

    const token = {} as Token;
    if (accessToken) {
      token.accessToken = accessToken;
    }
    if (accessToken) {
      token.accessToken = accessToken;
    }
    if (refreshToken) {
      token.refreshToken = refreshToken;
    }

    if (expiresAt) {
      token.expiresAt = moment.unix(Number(expiresAt)).toDate();
    }

    return token;
  }

  store(token: Token): void {
    const removeExpirationDate = new Date();
    removeExpirationDate.setTime(0);

    if (token) {
      if (token.refreshToken) {
        this.cookieService.put(environment.cookieRefreshTokenName, token.refreshToken, {
          expires: moment().add(1209600, 'seconds').toDate()
        });
      } else {
        this.cookieService.put(environment.cookieRefreshTokenName, undefined, {expires: removeExpirationDate});
      }

      if (token.expiresAt) {
        if (token.accessToken) {
          this.cookieService.put(environment.cookieAccessTokenName, token.accessToken, {
            expires: token.expiresAt
          });
        } else {
          this.cookieService.put(environment.cookieAccessTokenName, undefined, {expires: removeExpirationDate});
        }

        if (token.type) {
          this.cookieService.put(environment.cookieType, token.type, {
            expires: token.expiresAt
          });
        } else {
          this.cookieService.put(environment.cookieType, undefined, {expires: removeExpirationDate});
        }

        if (token.expiresAt) {
          this.cookieService.put(environment.cookieExpiresName, token.expiresAt.toString(), {
            expires: token.expiresAt
          });
        } else {
          this.cookieService.put(environment.cookieExpiresName, undefined, {expires: removeExpirationDate});
        }
      }
    } else {
      this.clear();
    }
  }

  clear(): void {
    const removeExpirationDate = new Date();
    removeExpirationDate.setTime(0);
    this.cookieService.put(environment.cookieAccessTokenName, undefined, {expires: removeExpirationDate});
    this.cookieService.put(environment.cookieRefreshTokenName, undefined, {expires: removeExpirationDate});
    this.cookieService.put(environment.cookieType, undefined, {expires: removeExpirationDate});
    this.cookieService.put(environment.cookieExpiresName, undefined, {expires: removeExpirationDate});
    this.tokenSubject.next(undefined);
  }
}
