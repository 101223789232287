import { SidebarNavigationLink } from '@models/sidebar-navigation';
import { backLink, getCommonLink, title } from './sidebar-commons';
import { UserRoles } from '@models/user-roles';
import { ProfileIntegrations } from '@models/profile-integrations';
import { MenuConfig } from '@models/menu-config';
import { Services } from '@models/services';

export function getProfileSidebarNavigationLink(profile, menuConfig: MenuConfig, profileIntegrations?: ProfileIntegrations, services?: Services): SidebarNavigationLink[] {
  const subMenuStats = [] as SidebarNavigationLink[];
  let subMenuParameters = [] as SidebarNavigationLink[];

  if (menuConfig.family_space) {
    subMenuStats.push( {
      type: 'link', label: 'Espace famille', url: [`/${profile.id}/stats/family-space`], icon: 'family',
      queryParamsHandling: 'merge', queryParams: {agency: null}, activeLink: [`/${profile.id}/stats/family-space`, `/${profile.id}/stats/condolence` ]
    });
  }

  if (profileIntegrations.analyticsPropertyId) {
    subMenuStats.push(
      {type: 'link', label: 'Site internet', url: [`/${profile.id}/stats/website`], icon: 'webpage', queryParamsHandling: 'merge', queryParams: {agency: null}},
    );
  }

  if (profileIntegrations?.nimbataBrand || menuConfig.shop || profile?.soCompanyId)  {
    subMenuStats.push(
      {type: 'link', label: 'Performances digitales', url: [
          (profileIntegrations?.nimbataBrand) ? `/${profile.id}/stats/call` : (profile?.soCompanyId) ?  `/${profile.id}/stats/estimate` : `/${profile.id}/stats/order`
        ], icon: 'desktop', queryParamsHandling: 'merge', queryParams: {agency: null},
        activeLink: [`/${profile.id}/stats/call`, `/${profile.id}/stats/estimate`, `/${profile.id}/stats/order` ]},
    );
  }

  subMenuStats.push(
    {type: 'link', label: 'Performances Google', url: [`/${profile.id}/stats/google`], icon: 'business', queryParamsHandling: 'preserve',
      activeLink: [`/${profile.id}/stats/google`, `/${profile.id}/stats/review`]});

  if (profileIntegrations.adsId) {
    subMenuStats.push(
      {type: 'link', label: 'Google Ads', url: [`/${profile.id}/stats/digital-performance`], icon: 'adwords', queryParamsHandling: 'merge', queryParams: {agency: null},
        activeLink: [`/${profile.id}/stats/digital-performance`, `/${profile.id}/stats/visibility`, `/${profile.id}/stats/conversion`]}
    );
  }

  if (menuConfig.shop || menuConfig.udiana || menuConfig.grafe || menuConfig.tribute_book) {
    subMenuStats.push(
      {type: 'link', label: 'E-commerce', url: [`/${profile.id}/stats/shop`], icon: 'cart', queryParamsHandling: 'merge', queryParams: {agency: null},
        activeLink: [`/${profile.id}/stats/shop`, `/${profile.id}/stats/udiana`, `/${profile.id}/stats/grafe`, `/${profile.id}/stats/tribute-book`]}
    );
  }

  if (services && (services.sorenirEnabled || services.reposDigitalEnabled || services.caelisEnabled)) {
    subMenuStats.push(
      {type: 'link', label: 'Partenaires', url: [(services.sorenirEnabled) ? `/${profile.id}/stats/sorenir` : (services.reposDigitalEnabled) ? `/${profile.id}/stats/repos-digital`: `/${profile.id}/stats/caelis`], icon: 'partners', queryParamsHandling: 'merge', queryParams: {agency: null},
        activeLink: [`/${profile.id}/stats/sorenir`, `/${profile.id}/stats/repos-digital`, `/${profile.id}/stats/caelis`]}
    );
  }

  let menu: SidebarNavigationLink[] = [
    backLink('Retour à la vue "Compte"', '/computed-stats'),
    title(`Profil - ${profile.name}`),
    {
      type: 'link',
      label: 'Tableau de bord',
      url: [`/${profile.id}/home`],
      icon: 'dashboard',
    },
    ...getCommonLink(menuConfig, profile),
    {
      type: 'link',
      label: 'Statistiques',
      url: [`/${profile.id}/stats`],
      icon: 'stats',
      subNav: subMenuStats
    }
  ];

  if (menuConfig.reviews) {
    menu.push({
      type: 'link',
      label: 'Avis clients',
      url: [`/${profile.id}/reviews/manage`],
      icon: 'review',
      subNav: [
        {type: 'link', label: 'Gérer ses avis', url: [`/${profile.id}/reviews/manage`], icon: 'manege-review'},
        {type: 'link', label: 'Collecter des avis', url: [`/${profile.id}/reviews/collect`], icon: 'collect-review'}
      ]
    });
  }

  subMenuParameters = [
    {
      type: 'link',
      label: 'Informations',
      url: [`/${profile.id}/setting`],
      icon: 'info'
    },
    {
      type: 'link',
      label: 'Services externes',
      url: [`/${profile.id}/external-services`],
      icon: 'customer-service',
      requiredRoles: [UserRoles.SUPER_ADMIN]
    },
    {
      type: 'link',
      label: 'Outils métiers',
      url: [`/${profile.id}/business-tools`],
      icon: 'cms',
      requiredRoles: [UserRoles.SUPER_ADMIN]
    },
    {type: 'link', label: 'Établissements', url: [`/${profile.id}/establishments`], icon: 'home'},
  ];

  if (profile?.soCompanyId) {
    subMenuParameters.push({type: 'link', label: 'Devis en ligne', url: [`/${profile.id}/serenite-obseques`], icon: 'catalogue', subNav: [
        {type: 'link', label: 'Catalogue', url: [`/${profile.id}/serenite-obseques/catalogue`], icon: 'open-book'},
        {
          type: 'link',
          label: 'Funérariums',
          url: [`/${profile.id}/serenite-obseques/funerariums`],
          icon: 'houses'
        },
      ]});
  }

  subMenuParameters = subMenuParameters.concat([
    {type: 'link', label: 'Personnalisation', url: [`/${profile.id}/customize`], icon: 'customize'},
    {type: 'link', label: 'Préférences', url: [`/${profile.id}/preferences`], icon: 'settings-2', subNav: [
        {type: 'link', label: 'Préférences par défaut', url: [`/${profile.id}/preferences/default-notifications`], icon: 'preferences-default'},
        {type: 'link', label: 'Préférences emails', url: [`/${profile.id}/preferences/notifications-center`], icon: 'preferences-emails', requiredRoles: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN]}
      ]},
  ]);

  menu = menu.concat([
    {
      type: 'link',
      label: 'Paramètres',
      url: [`/${profile.id}/setting`],
      icon: 'settings',
      requiredRoles: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN],
      subNav: subMenuParameters
    }
  ]);

  return menu;
}
