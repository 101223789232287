import { Pipe, PipeTransform } from '@angular/core';
import { StateUdiana } from '@models/state-udiana';

@Pipe({
  name: 'stateUdiana',
  standalone: true
})
export class StateUdianaPipe implements PipeTransform {

  transform(value: string): any {
    const state = StateUdiana.find((params) => params.value === value);
    return '<span><i class="icon ' + state.icon + ' me-2"></i><span>' + state.label + '</span></span>';
  }
}
