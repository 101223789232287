import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
/** MODULES */
import { AppRoutingModule } from './app-routing.module';
import { CookieModule } from 'ngx-cookie';
/** COMPONENTS */
import { AppComponent } from './app.component';
/** ENVIRONMENTS */
import { environment } from '../environments/environment';
/** INTERCEPTORS */
import { OauthInterceptor } from './interceptors/oauth.interceptor';
/** MODELS */
import { modalOptions } from '@models/modalOptions';
/** SERVICES */
import { TokenCookieStorageService } from '@services/token-cookie-storage.service';
import { OauthService } from '@services/oauth.service';
import { FormService } from '@services/form.service';
/** PIPES */
import { PrefixPipe } from '@pipes/prefix.pipe';
import { ShopBooleanPipe } from '@pipes/shop-boolean.pipe';
import { CodolenceStatePipe } from '@pipes/codolence-state.pipe';
import { CondolencePipe } from '@pipes/condolence.pipe';
/** ANGULAR MATERIAL */
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
/** HELPERS */
/** INTERNATIONALIZATION */
import { MatPaginatorIntlFr } from './internationalization/angular-material-paginator';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { ProfilesResolver } from '@resolvers/profiles.resolver';
import { GlobalErrorHandler } from '@utils/global-error-handler';

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    PrefixPipe,
    ShopBooleanPipe,
    CodolenceStatePipe,
    CondolencePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    CookieModule.forRoot({
      path: '/',
      domain: environment.cookieDomain
    }),
    GoogleMapsModule
  ],
  exports: [
    BrowserAnimationsModule,
    BrowserModule,
  ],
  providers: [
    OauthService,
    TokenCookieStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass : OauthInterceptor,
      multi : true
    },
    CurrencyPipe,
    PrefixPipe,
    FormService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: modalOptions},
    { provide: LOCALE_ID, useValue: 'fr-FR'},
    ShopBooleanPipe,
    CodolenceStatePipe,
    CondolencePipe,
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlFr,
    },
    {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher},
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    ProfilesResolver
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
