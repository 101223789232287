import { Injectable } from '@angular/core';
import { distinctUntilChanged, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class MediaQueriesService {

  constructor(
    private breakpointObserver: BreakpointObserver
  ) { }

  mediaQueriesMatches(mediaQueries: string): Observable<BreakpointState> {
    return this.breakpointObserver.observe(mediaQueries);
  }
  getCurrentMediaQueries(): Observable<string> {
    return this.breakpointObserver.observe(['(min-width: 1200px)', '(min-width: 992px)', '(min-width: 768px)', '(min-width: 576px)'])
      .pipe(
        distinctUntilChanged(),
        switchMap(() => {
          switch(true) {
            case this.breakpointObserver.isMatched('(min-width: 1200px)'):
              return of('xl');
              break;
            case this.breakpointObserver.isMatched('(min-width: 992px)'):
              return of('lg');
              break;
            case this.breakpointObserver.isMatched('(min-width: 768px)'):
              return of('md');
              break;
            case this.breakpointObserver.isMatched('(min-width: 0px)'):
              return of('sm');
              break;
            default:
              return of('');
          }
        })
      );
  }
}
