import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomerConfig } from '@models/customer-config';
import { BaseService } from '@services/base.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerConfigResolver {
  constructor(private baseService: BaseService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<null | CustomerConfig> {
    if (window.location.host !== environment.url) {
      return this.baseService.get(
        `/style/customer?domain=${window.location.hostname}`
      );
    } else {
      return of(null);
    }
  }
}
