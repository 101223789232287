import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProfileService } from '@services/profile.service';
import { Services } from '@models/services';

@Injectable({
  providedIn: 'root'
})
export class ProfileServicesResolver  {

  constructor(
    private router: Router,
    private profileService: ProfileService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Services | null> {
    return this.profileService.getProfileServices(route.params.profileId).pipe(
      catchError(() => {
          this.router.navigate(['/unauthorized']);
          return of(null);
        }
      ));
  }
}
