import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
/** RXJS */
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
/** ENVIRONMENTS */

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  constructor(private httpClient: HttpClient) {}

  post<T>(endpoint: string, body: any, options?: any): Observable<any> {
    return this.httpClient.post<T>(
      `${environment.apiUrl}${endpoint}`,
      body,
      options
    );
  }

  get<T>(endpoint: string, options?: any): Observable<any> {
    return this.httpClient.get<T>(`${environment.apiUrl}${endpoint}`, options);
  }

  delete<T>(endpoint: string, options?: any): Observable<HttpEvent<T>> {
    return this.httpClient.delete<T>(
      `${environment.apiUrl}${endpoint}`,
      options
    );
  }

  patch<T>(endpoint: string, body: any, options?: any): Observable<any> {
    return this.httpClient.patch<T>(
      `${environment.apiUrl}${endpoint}`,
      body,
      options
    );
  }

  formatObjectFormData(obj, form = null, namespace = null): FormData {
    const fd = form || new FormData();
    let formKey;

    for (const property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (namespace) {
          formKey = namespace + '[' + property + ']';
        } else {
          formKey = property;
        }
        if (
          typeof obj[property] === 'object' &&
          !(obj[property] instanceof File)
        ) {
          this.formatObjectFormData(obj[property], fd, formKey);
        } else {
          fd.append(formKey, obj[property]);
        }
      }
    }

    return fd;
  }

  formatFormData(data): FormData {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        data[key].forEach((value) => {
          formData.append(key, value);
        });
      } else {
        formData.append(key, data[key]);
      }
    });
    return formData;
  }
}
