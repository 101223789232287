<div class="profile-selector">
  <button mat-flat-button color="primary" [matMenuTriggerFor]="menuProfile">
    <ng-container *ngIf="!currentProfile">Choix d'un profil</ng-container>
    <ng-container *ngIf="currentProfile">
        {{ currentProfile?.name }}
    </ng-container>
     <span class="icon caret profile-selector__icon d-inline-flex mx-1"></span>
  </button>
  <mat-menu #menuProfile="matMenu" class="profile-selector__panel">
    <div class="profile-selector__content p-3" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <mat-form-field [style.--mat-input-text]="customerConfig?.style?.hover">
        <mat-label>Recherche</mat-label>
        <input type="text" placeholder="Recherche" aria-label="Recherche.." matInput [(ngModel)]="term">
      </mat-form-field>
      <div class="profile-selector__list py-3">
        <ng-container *appHasRole="[roles.ADMIN, roles.STANDARD]">
          <div *ngFor="let profile of (profiles | filter: term : false); let even = even; let odd = odd" class="profile-selector__line" [ngClass]="{ 'profile-selector__line--odd': odd, 'profile-selector__line--even': even }">
            <div class="profile-selector__line py-1 px-3">
              <a [routerLink]="['/', profile.id]" title="{{ profile.name }}" (click)="toggleProfileSelector()"><span [innerHtml]="profile.name | higlightTerm: term"></span></a>
              <a [routerLink]="['/', profile.id, 'setting']"  title="{{ profile.name }}" (click)="toggleProfileSelector()"><span class="icon gear profile-selector__icon"></span></a>
            </div>
          </div>
        </ng-container>
        <ng-container *appHasRole="[roles.SUPER_ADMIN]">
      <span *ngFor="let profile of (profiles | filter: term : true);"
            [style.--bs-link-color]="customerConfig?.style?.links"
            [style.--bs-link-hover-color]="customerConfig?.style?.links">
        <span class="profile-selector__customer" [innerHtml]="profile.name | higlightTerm: term"></span>
        <div *ngFor="let p of profile.values; let even = even; let odd = odd" class="profile-selector__line" [ngClass]="{ 'profile-selector__line--odd': odd, 'profile-selector__line--even': even }">
          <div class="profile-selector__line py-1 px-3">
            <a [routerLink]="['/', p.id]" title="{{ profile.name }}" (click)="toggleProfileSelector()"><span [innerHtml]="p.name | higlightTerm: term"></span></a>
            <a [routerLink]="['/', p.id, 'setting']" title="{{ profile.name }}" (click)="toggleProfileSelector()"><span class="icon gear profile-selector__icon"></span></a>
          </div>
        </div>
      </span>
        </ng-container>
      </div>
    </div>
  </mat-menu>
</div>
