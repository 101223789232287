import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enum'
})
export class EnumPipe implements PipeTransform {

  transform(value: any, args: any): any {
    if (args.hasOwnProperty(value)) {
      return args[value];
    } else {
      return value;
    }
  }
}
