export const StateUdiana = [
  {
    label: 'Validée',
    value: 'paid',
    icon: 'active',
  },
  {
    label: 'Annulée',
    value: 'cancelled',
    icon: 'disabled',
  },
];
